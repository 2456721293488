import { lazy } from 'react';

const FeedbackHeader = lazy(() => import('./FeedbackHeader'));
const FeedbackTable = lazy(() => import('./FeedbackTable'));
const AddOrUpdateFeedbackModal = lazy(() => import('./AddOrUpdateFeedbackModal'));
const FeedbackForm = lazy(() => import('./FeedbackForm'));
const CallerTypeForm = lazy(() => import('./CallerTypeForm'));
const FailureDropdownsForm = lazy(() => import('./FailureDropdownsForm'));
const DateForm = lazy(() => import('./DateForm'));
const AdditionalDetailsForm = lazy(() => import('./AdditionalDetailsForm'));

export {
  FeedbackHeader,
  FeedbackTable,
  AddOrUpdateFeedbackModal,
  FeedbackForm,
  CallerTypeForm,
  FailureDropdownsForm,
  DateForm,
  AdditionalDetailsForm
};
