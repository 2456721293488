import { cast, types } from 'mobx-state-tree';
import Dropdownmodel from './DropdownModel';
import UserDropdownModel from './UsersDropdownModel';

const FeedbackModel = types
  .model('FeedbackModel', {
    id: types.optional(types.integer, 0),
    key: types.optional(types.integer, 0),
    reporter_name: types.optional(types.string, ''),
    feedback_date: types.optional(types.string, ''),
    order_number: types.optional(types.string, ''),
    feedback_type: types.optional(types.string, ''),
    handling_by: types.optional(types.maybeNull(types.string), ''),
    status: types.optional(types.string, ''),
    reporter_category: types.optional(types.maybeNull(types.string), ''),
    company_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    zip_code: types.optional(types.maybeNull(types.number), 0),
    position: types.optional(types.maybeNull(types.string), ''),
    city: types.optional(types.maybeNull(types.string), ''),
    street_no: types.optional(types.maybeNull(types.string), ''),
    phone_number: types.optional(types.maybeNull(types.string), ''),
    email: types.optional(types.maybeNull(types.string), ''),
    event_date: types.optional(types.maybeNull(types.string), ''),
    event_time: types.optional(types.maybeNull(types.string), ''),
    event_place: types.optional(types.maybeNull(types.string), ''),
    failure_class: types.optional(types.maybeNull(types.string), ''),
    failure_class_parent: types.optional(types.maybeNull(types.string), ''),
    event_details: types.optional(types.maybeNull(types.string), ''),
    affected_employee: types.optional(types.maybeNull(types.string), ''),
    internal_measures: types.optional(types.maybeNull(types.string), ''),
    form_of_execution: types.optional(types.maybeNull(types.string), ''),
    registered_by: types.optional(types.maybeNull(types.string), ''),
    feedback_type_list: types.optional(types.array(Dropdownmodel), []),
    reporter_category_list: types.optional(types.array(Dropdownmodel), []),
    status_list: types.optional(types.array(Dropdownmodel), []),
    failure_class_list: types.optional(types.array(Dropdownmodel), []),
    child_dropdown_list: types.optional(types.array(Dropdownmodel), []),
    internal_measures_list: types.optional(types.array(Dropdownmodel), []),
    form_of_execution_list: types.optional(types.array(Dropdownmodel), []),
    UserHandlingBy: types.optional(types.maybeNull(UserDropdownModel, {})),
    currentSelectedDropdown: types.optional(types.string, 'Feedback_#None Selected')
  })
  .views((self) => ({}))
  .actions((self) => ({
    setDropdownList(type, list) {
      self[`${type}_list`] = cast(list);
    },

    setCurrentSelectedDropdown(currentSelected) {
      self.currentSelectedDropdown = currentSelected;
    }
  }));

export default FeedbackModel;
