import { cast, types } from 'mobx-state-tree';

const FeedbackManagementUtilities = types
  .model('FeedbackManagementUtilities', {
    showAddFeedbackModal: types.optional(types.boolean, false),
    search: types.optional(types.string, ''),
    props: types.optional(types.array(types.string), [
      'company_name',
      'last_name',
      'zip_code',
      'city',
      'street_no',
      'phone_number',
      'email',
      'feedback_date',
      'order_number',
      'feedback_type',
      'handling_by',
      'status'
    ]),

    isUpdate: types.optional(types.boolean, false),
    updateId: types.optional(types.integer, 0),
    selectedRowsKeys: types.optional(types.array(types.integer), []),
    isDownloading: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    setToggleShowAddOrUpdateFeedbackModal(isUpdate = false) {
      self.isUpdate = isUpdate ? true : false;
      self.showAddOrUpdateFeedbackModal = !self.showAddOrUpdateFeedbackModal;
    },
    setSelectedRowsKeys(selectedRowsKeys) {
      console.log(selectedRowsKeys, 'selectedRowsKeys');
      self.selectedRowsKeys = cast(selectedRowsKeys);
    },

    setIsDownloading(isDownloading) {
      self.isDownloading = isDownloading;
    },

    setSearch(searchValue) {
      self.search = searchValue;
    },
    setUpdateId(id) {
      self.updateId = id;
    },

    getDateFormat(locale) {
      switch (locale) {
        case 'de':
          return 'DD.MM.YYYY';
        default:
          return 'DD-MM-YYYY';
      }
    }
  }));

export default FeedbackManagementUtilities;
