import { cast, types, flow } from 'mobx-state-tree';
import FeedbackModel from './FeedbackModel';
import ApplyRootCrud from '@core_state_management/Helpers/RootCrud';
import UserDropdownModel from './UsersDropdownModel';
import axios from 'axios';

const API_PATH = '/api/feedback';
const API_USER_PATH = '/api/users';
const API_EXPORT_PATH = '/api/feedback/export_csv';

export default types
  .model('RckvergtungenState', {
    state: types.optional(types.array(FeedbackModel), []),
    userDropdownState: types.optional(types.array(UserDropdownModel), []),
    singleState: types.optional(FeedbackModel, {}),
    total: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    isCreating: types.optional(types.boolean, false)
  })
  .actions((self) => ({
    ...ApplyRootCrud(API_PATH, self),

    getUsersDropdown: flow(function* () {
      try {
        let result = yield axios.get(API_USER_PATH);
        self.userDropdownState = cast(result.data.data);
      } catch (error) {
        console.log(error);
      }
    }),

    exportData: flow(function* () {
      return yield axios.get(API_EXPORT_PATH);
    }),

    getCSV: flow(function* () {
      return yield axios({
        url: '/public/export/export_file.csv', //your url
        method: 'GET'
        // responseType: 'blob' // important
      });
    }),

    deleteFile: flow(function* () {
      yield axios.delete('/api/feedback/delete_csv');
    })
  }))
  .views((self) => ({}));
