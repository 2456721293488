import { types } from 'mobx-state-tree';

const UserDropdownModel = types
  .model('UserDropdownModel', {
    id: types.optional(types.string, ''),
    user_type_id: types.optional(types.number, 0),
    first_name: types.optional(types.string, ''),
    last_name: types.optional(types.string, ''),
    email: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({}));

export default UserDropdownModel;
