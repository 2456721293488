import React, { Suspense, useState } from 'react';
import { Form } from 'antd';
import { inject, observer } from 'mobx-react';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { FeedbackTable, FeedbackHeader } from '@app_modules/Feedback/components';

function Feedback() {
  const [form] = Form.useForm();

  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  return (
    <div>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <FeedbackHeader
          form={form}
          isDeleteVisible={isDeleteVisible}
          setIsDeleteVisible={setIsDeleteVisible}
        />
      </Suspense>

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <FeedbackTable form={form} setIsDeleteVisible={setIsDeleteVisible} />
      </Suspense>
    </div>
  );
}

export default inject('store')(observer(Feedback));
