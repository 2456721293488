import React from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import Routes from '@app_routing/Routes';

const AppMenus = [
  {
    key: '3',
    icon: <PhoneOutlined />,
    route: Routes.FEEDBACK_ROUTE,
    menuName: 'Feedback'
  }
];

export default AppMenus;
